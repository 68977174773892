import "./style.css";
import { getScroll, getRaf } from "@app";
import { clamp, map } from "@utils/math";

export default class Manifesto {
  static selector = ".manifesto";

  constructor(block) {
    this.block = block;
    this.textNode = block.querySelector(".manifesto__text");
    this.imageWithTextNode = block.querySelector(".manifesto__image-with-text");
    this.imageNode = block.querySelector(
      ".manifesto__image-with-text__wrapper__image"
    );
    this.wrapperNode = block.querySelector(
      ".manifesto__image-with-text__wrapper"
    );
    this.textCloneNode = block.querySelector(
      ".manifesto__image-with-text__wrapper__text"
    );

    this.scrollY = 0;
    this.animations = {
      imageWithText: {
        node: this.imageWithTextNode,
        styles: {
          transform: {
            style: () => {
              const value =
                this.animations[`imageWithText`].styles.transform.current;

              return `translate(-50%, ${value}px)`;
            },
            dampingFactor: 1,
            toValue: 0,
            fromValue: 0,
            current: 0,
            setValue: () => {
              this.animations[`image`].styles.transform.fromValue =
                this.animations[`image`].styles.transform.fromValue +
                (this.animations[`image`].styles.transform.toValue -
                  this.animations[`image`].styles.transform.fromValue) *
                  this.animations[`image`].styles.transform.dampingFactor;

              return this.animations[`image`].styles.transform.fromValue;
            },
          },
        },
      },
    };
  }

  updateTextNode = () => {
    this.textCloneNode.style.width = `${this.textNode.calculatedBounds.width}px`;
    this.textCloneNode.style.transform = `translate(-50%, ${
      this.textNode.calculatedBounds.offsetTop -
      this.y -
      this.windowSizes.height / 2 +
      this.wrapperNode.calculatedBounds.height / 2
    }px)`;
  };

  layout = () => {
    /*for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].node.style[key2] =
          this.animations[key1].styles[key2].style();
      }
    }*/
  };

  render = () => {
    /* for (const key1 in this.animations) {
      for (const key2 in this.animations[key1].styles) {
        this.animations[key1].styles[key2].current =
          this.animations[key1].styles[key2].setValue();
      }
    }*/

    const { height, offsetTop } = this.block.calculatedBounds;
    const { height: wrapperHeight } = this.wrapperNode.calculatedBounds;
    const { height: wHeight } = this.windowSizes;
    const { height: imageHeight } = this.imageNode.calculatedBounds;
    const y = this.scrollY + wHeight;

    const progress = clamp(
      map(y, offsetTop, offsetTop + wHeight / 2, 0, 1),
      0,
      1
    );
    this.imageWithTextNode.style.transform = `translateY(${
      (-imageHeight / 2) * (1 - progress)
    }px)`;

    const progress1 = clamp(
      map(y, offsetTop + height - wHeight / 2, offsetTop + height, 0, 1),
      0,
      1
    );
    this.wrapperNode.style.clipPath = `polygon(0 ${100 * progress1}%, 100% ${
      100 * progress1
    }%, 100% 100%, 0 100%)`;

    this.y = clamp(
      map(this.scrollY, offsetTop, offsetTop + height, 0, height),
      0,
      height
    );

    this.updateTextNode();

    this.layout();
  };

  onScrollPositionChange = (event) => {
    const { animatedScroll } = event;
    this.scrollY = animatedScroll;
    /*const progress = clamp(map(y, top, top + height + wHeight, 0, 1), 0, 1);
    const imageYProgress = clamp(
      map(progress, 0, 0.2, 0, wHeight / 2),
      0,
      wHeight / 2
    );

    const progress1 = clamp(map(animatedScroll, top, top + height, 0, 1), 0, 1);
    const imageY1Progress = clamp(progress1 * height * 0.8, 0, height * 0.8);

    this.animations[`image`].styles.transform.toValue =
      imageYProgress - wrapperHeight / 2;*/
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);

      this.raf = getRaf();
      this.raf.register("manifesto", this.render);

      resolve();
    });
  };

  onResize = () => {
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.block.calculatedBounds = this.block.getBoundingClientRect();
    this.block.calculatedBounds.offsetTop = this.block.offsetTop;

    this.wrapperNode.calculatedBounds =
      this.wrapperNode.getBoundingClientRect();
    this.wrapperNode.calculatedBounds.offsetTop = this.wrapperNode.offsetTop;

    this.imageNode.calculatedBounds = this.imageNode.getBoundingClientRect();

    this.textNode.calculatedBounds = this.textNode.getBoundingClientRect();
    this.textNode.calculatedBounds.offsetTop = this.textNode.offsetTop;

    this.updateTextNode();
  };
}
