import "./style.css";
import Marquee from "@blocks/Marquee";

export default class Footer {
  static selector = ".footer";

  constructor(block) {
    this.block = block;
    this.marqueeNode = block.querySelector(".marquee");
    this.marquee = new Marquee(this.marqueeNode);
  }

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      await this.marquee.onReady();

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      resolve();
    });
  };

  onResize = () => {
    const { height } = this.block.getBoundingClientRect();
    document.querySelector("main").style.paddingBottom = `${height}px`;
    this.marquee.onResize();
  };
}
