import "./style.css";
import Marquee from "@blocks/Marquee";
import { getScroll } from "@app";

export default class Header {
  static selector = ".header";

  constructor(block) {
    this.block = block;
    this.marqueeNode = block.querySelector(".marquee");
    this.marquee = new Marquee(this.marqueeNode);
  }

  onScrollPositionChange = (event) => {
    const { direction } = event;
    this.block.classList.toggle("header--hidden", direction == 1);
  };

  onReady = () => {
    return new Promise(async (resolve, reject) => {
      this.mounted = true;

      const { height } = this.block.getBoundingClientRect();
      document.documentElement.style.setProperty(
        "--header-height",
        `${height}px`
      );

      await this.marquee.onReady();

      resolve();
    });
  };

  onComplete = () => {
    return new Promise(async (resolve, reject) => {
      this.onResize();

      /*this.scroll = getScroll();
      this.scroll.registerOnScrollPositionChange(this.onScrollPositionChange);*/

      resolve();
    });
  };

  onResize = () => {
    const { height } = this.block.getBoundingClientRect();
    document.documentElement.style.setProperty(
      "--header-height",
      `${height}px`
    );
    this.marquee.onResize();
  };
}
